import React from 'react'
import {Link} from 'gatsby'
import event1 from '../../assets/images/events/event1.jpg'
import event2 from '../../assets/images/events/event2.jpg'
import event3 from '../../assets/images/events/event3.jpg'
import event4 from '../../assets/images/events/event4.jpg'
import event5 from '../../assets/images/events/event5.jpg'
import event6 from '../../assets/images/events/event6.jpg'
import event7 from '../../assets/images/events/event7.jpg'
import event8 from '../../assets/images/events/event8.jpg'
import event9 from '../../assets/images/events/event9.jpg'

const EventsCard = () => {
    return (
        <div className="events-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-events-box">
                            <div className="image">
                                    <img src={event1} alt="event" />
                            </div>

                            <div className="content">
                                <h3>
                                    Oradea
                                </h3>
                                <span className="location">
                                    <i className="bx bx-map"></i> România
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-events-box">
                            <div className="image">
                                    <img src={event2} alt="event" />
                            </div>

                            <div className="content">
                                <h3>
                                     Timișoara
                                </h3>
                                <span className="location">
                                    <i className="bx bx-map"></i> România
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-events-box">
                            <div className="image">
                                    <img src={event3} alt="event" />
                            </div>

                            <div className="content">
                                <h3>
                                     Cluj-Napoca
                                </h3>
                                <span className="location">
                                    <i className="bx bx-map"></i> România
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-events-box">
                            <div className="image">
                                    <img src={event4} alt="event" />
                            </div>

                            <div className="content">
                                <h3>
                                     Oakville
                                </h3>

                                <span className="location">
                                    <i className="bx bx-map"></i> Ontario, Canada
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EventsCard